import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { useSiteMetadata } from "../hooks/siteMetadata"

import About from "../components/About"
import Jumbotron from "../components/Jumbotron"
import Layout from "../components/Layout"
import Posts from "../components/Posts"

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  const { intro } = useSiteMetadata()

  return (
    <Layout>
      <Helmet>
        <meta name="description" content={intro} />
      </Helmet>

      <Jumbotron description={intro} isMasthead={true} />
      <Posts posts={posts} />
      <About />
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "posts" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            date
            formattedDate: date(formatString: "MMMM Do, YYYY")
          }
        }
      }
    }
  }
`

export default IndexPage
