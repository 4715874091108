import React from "react"

import styles from "../scss/components/Jumbotron.module.scss"

const Jumbotron = ({ title, description, isMasthead }) => {
  const headerClasses = [styles.container]

  if (isMasthead) {
    headerClasses.push(styles.masthead)
  }

  return (
    <header className={headerClasses.join(" ")}>
      <div className={styles.wrapper}>
        {title && (
          <h1 className={styles.title}>
            {title}
          </h1>
        )}

        {description && (
          <h2 className={styles.description}>
            {description}
          </h2>
        )}

        {isMasthead && (
          <div className={styles.bar} />
        )}
      </div>
    </header>
  )
}

export default Jumbotron
