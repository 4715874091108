import React from "react"

import Link from "../components/Link"

import styles from "../scss/components/About.module.scss"


const ToBucketd = ({ children }) => (
  <Link to="https://www.bucketd.com" target="_blank">{children}</Link>
)

const ToGitHub = ({ children }) => (
  <Link to="https://github.com/seeruk" target="_blank">{children}</Link>
)

const ToGolang = ({ children }) => (
  <Link to="https://golang.org/" target="_blank">{children}</Link>
)

const ToIcelolly = ({ children }) => (
  <Link to="https://www.icelolly.com/" target="_blank">{children}</Link>
)


const About = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4>About</h4>

        <p>
          I&apos;m currently Head of Software Engineering at <ToIcelolly>icelolly.com</ToIcelolly>. I
          manage the software engineering team, but most of my time is still spent getting my hands
          dirty. I mainly focus on writing backend services with <ToGolang>Go</ToGolang>, but also
          help maintain our infrastructure on Kubernetes. My main goals in this role have been
          making it easier and faster for developers to focus on writing and releasing robust
          applications. I&apos;ve been working with go since March, 2016.
        </p>

        <p>
          In my spare time I enjoying bouldering, cycling, playing the piano; and of course, working
          on projects <ToGitHub>of my own</ToGitHub> or <ToBucketd>with friends</ToBucketd>.
        </p>
      </div>
    </div>
  )
}

export default About
